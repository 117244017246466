@font-face {
  font-family: "Shapiro";
  font-weight: 200;
  font-display: swap;
  src: url("./fonts/Shapiro-35-Feather-Text.woff") format("woff"),
    url("./fonts/Shapiro-35-Feather-Text.ttf") format("ttf");
}
@font-face {
  font-family: "Shapiro";
  font-weight: 300;
  font-display: swap;
  src: url("./fonts/Shapiro-45-Welter-Text.woff") format("woff"),
    url("./fonts/Shapiro-45-Welter-Text.ttf") format("ttf");
}
@font-face {
  font-family: "Shapiro";
  font-weight: 400;
  font-display: swap;
  src: url("./fonts/Shapiro-55-Middle-Text.woff") format("woff"),
    url("./fonts/Shapiro-55-Middle-Text.ttf") format("ttf");
}
@font-face {
  font-family: "Shapiro Wide";
  font-weight: 400;
  font-display: swap;
  src: url("./fonts/Shapiro-65-Light-Heavy-Extd.woff") format("woff"),
    url("./fonts/Shapiro-65-Light-Heavy-Extd.ttf") format("ttf");
}
@font-face {
  font-family: "Shapiro Wide";
  font-weight: 500;
  font-display: swap;
  src: url("./fonts/Shapiro-65-Light-Heavy-Wide.woff") format("woff"),
    url("./fonts/Shapiro-65-Light-Heavy-Wide.ttf") format("ttf");
}
@font-face {
  font-family: "Shapiro";
  font-weight: 500;
  font-display: swap;
  src: url("./fonts/Shapiro-75-Heavy-Text.woff") format("woff"),
    url("./fonts/Shapiro-75-Heavy-Text.ttf") format("ttf");
}
@font-face {
  font-family: "Shapiro";
  font-weight: 700;
  font-display: swap;
  src: url("./fonts/Shapiro-85-Super-Heavy.woff") format("woff"),
    url("./fonts/Shapiro-85-Super-Heavy.ttf") format("ttf");
}
@font-face {
  font-family: "Shapiro Wide";
  font-weight: 700;
  font-display: swap;
  src: url("./fonts/Shapiro-85-Super-Heavy-Extd.woff") format("woff"),
    url("./fonts/Shapiro-85-Super-Heavy-Extd.ttf") format("ttf");
}
@font-face {
  font-family: "permanent_markerregular";
  src: url("./fonts/permanentmarker-regular-webfont.woff2") format("woff2"),
    url("./fonts/permanentmarker-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ogg";
  src: url("./fonts/Ogg-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Ogg";
  src: url("./fonts/Ogg-RegularItalic.woff2") format("woff2");
}
@font-face {
  font-family: "Ogg";
  src: url("./fonts/Ogg-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "Ogg";
  src: url("./fonts/Ogg-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "LabilGrotesk";
  font-weight: 500;
  src: url("./fonts/LabilGrotesk-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "LabilGrotesk";
  font-weight: 600;
  src: url("./fonts/LabilGrotesk-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "LabilGrotesk";
  font-weight: 700;
  src: url("./fonts/LabilGrotesk-Bold.woff2") format("woff2");
}

html,
button,
input,
select,
textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

body {
  font-family: "Shapiro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 300;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: 100ms ease-out all;
  /* this should exceed the $max z-index value in the theme, as we
  want modals to show above all other content */
  z-index: 1000;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  width: 640px;
}

.ReactModal__Content--wide {
  width: 800px;
}

.ReactModal__Content--super-wide {
  width: 1150px;
}

.ReactModal__Content--responsive {
  width: 1150px;
}

.ReactModal__Content--wide:focus,
.ReactModal__Content:focus {
  outline: none;
}

@media (max-width: 1599px) {
  .ReactModal__Content--responsive {
    width: 810px;
  }
}

@media (max-width: 832px) {
  .ReactModal__Content,
  .ReactModal__Content--wide,
  .ReactModal__Content--super-wide {
    width: 95%;
  }
  .ReactModal__Content--responsive {
    width: 100vw;
  }
}

.__react_component_tooltip.place-top:after {
  left: 20px !important;
}

.builder-text h1,
.builder-text h2,
.builder-text h3,
.builder-text h4 {
  font-family: "Shapiro Wide", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  text-transform: uppercase;
}
.builder-text h1 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
}
.builder-text h2 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}
.builder-text h3 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}
.builder-text h4 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}

.PhoneInput {
  display: block !important;
  position: relative;
}

.PhoneInputCountry {
  position: absolute !important;
  top: 60%;
  left: 18px;
  transform: translateY(-60%);
}

@media (min-width: 767px) {
  .PhoneInputCountry {
    top: 63%;
    transform: translateY(-60%);
  }
}

.PhoneInputCountryIcon {
  margin-right: 6px;
}

.PhoneInput input  {
  height: 61px ;
  border: 2px solid#CFCFCF;
  margin-top: 12px;
  border-radius: 8px;
  font-family: LabilGrotesk, sans-serif;
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  text-indent: 60px;
}